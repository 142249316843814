import { ReducerGraphic, ReduxActionData } from '../../types/reducers'
import { GRAPHIC_ACTIONS } from '../actions/graphic'

const defaultValue: ReducerGraphic = {
    // TODO: get the language from the browser
    // currentLanguage: navigator.language.substring(0, 2),
    currentLanguage: 'it',
    showNewsletterModal: true
}

const graphicState: (state: ReducerGraphic, action: ReduxActionData<any>) => ReducerGraphic = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case GRAPHIC_ACTIONS.SET_CURRENT_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload
            }
        case GRAPHIC_ACTIONS.SET_CURRENT_NEWSLETTER_MODAL:
            return {
                ...state,
                showNewsletterModal: action.payload
            }
        default:
            return state
    }
}

export default graphicState
